import facebookLogo from 'images/sociallink_facebook.svg';
import instagramLogo from 'images/sociallink_instagram.svg';
import twitterLogo from 'images/sociallink_twitter.svg';
import youtubeLogo from 'images/sociallink_youtube.svg';
import whatsappLogo from 'images/sociallink_whatsapp.svg';
import yacopiniLogo from 'images/yacopini-logo-white.png';
import './Footer.scss';

const Header = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer__data">
        <div className="footer__data__image">
          <img src={yacopiniLogo} height="56" alt="Yacopini Mirai" />
        </div>
        <h4>Yacopini Mirai</h4>
        <p>San Martín Sur 600. Godoy Cruz, Mendoza.</p>
      </div>
      <div className="footer__social">
        <a
          href="https://www.facebook.com/YacopiniNissan/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__social__link"
        >
          <img src={facebookLogo} alt="Facebook" />
        </a>
        <a
          href="https://twitter.com/YacopiniNissan"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__social__link footer__social__link-twitter"
        >
          <img src={twitterLogo} alt="Twitter" />
        </a>
        <a
          href="https://www.instagram.com/yacopininissan/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__social__link footer__social__link-instagram"
        >
          <img src={instagramLogo} alt="Instagram" />
        </a>

        <a
          href="https://web.whatsapp.com/send?phone=5492615336863 "
          target="_blank"
          rel="noopener noreferrer"
          className="footer__social__link footer__social__link-whatsapp"
        >
          <img src={whatsappLogo} alt="Whatsapp" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCaII_9QKsBYfnlUwhrQaE1g"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__social__link footer__social__link-youtube"
        >
          <img src={youtubeLogo} alt="YouTube" />
        </a>
      </div>
    </div>
  </footer>
);

export default Header;
